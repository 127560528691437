exports.components = {
  "component---src-pages-404-de-js": () => import("./../../../src/pages/404.de.js" /* webpackChunkName: "component---src-pages-404-de-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-catering-de-js": () => import("./../../../src/pages/catering.de.js" /* webpackChunkName: "component---src-pages-catering-de-js" */),
  "component---src-pages-catering-js": () => import("./../../../src/pages/catering.js" /* webpackChunkName: "component---src-pages-catering-js" */),
  "component---src-pages-contact-de-js": () => import("./../../../src/pages/contact.de.js" /* webpackChunkName: "component---src-pages-contact-de-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-general-terms-and-conditions-de-js": () => import("./../../../src/pages/general-terms-and-conditions.de.js" /* webpackChunkName: "component---src-pages-general-terms-and-conditions-de-js" */),
  "component---src-pages-general-terms-and-conditions-js": () => import("./../../../src/pages/general-terms-and-conditions.js" /* webpackChunkName: "component---src-pages-general-terms-and-conditions-js" */),
  "component---src-pages-imprint-de-js": () => import("./../../../src/pages/imprint.de.js" /* webpackChunkName: "component---src-pages-imprint-de-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-de-js": () => import("./../../../src/pages/index.de.js" /* webpackChunkName: "component---src-pages-index-de-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-de-js": () => import("./../../../src/pages/privacy.de.js" /* webpackChunkName: "component---src-pages-privacy-de-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

